<template>
  <div class="w-full p-16 pt-0">
    <h1>PRIVACY POLICY</h1>
    <p><br></p>
    <p><strong><span class="ql-cursor">﻿</span>Last updated May 25, 2022</strong></p>
    <p><br></p>
    <p><strong>﻿</strong>Medusa Distribution LLC&nbsp;(“we” or “us” or “our”) respects the&nbsp;privacy&nbsp;of our
      users (“user” or “you”). This&nbsp;Privacy&nbsp;Policy&nbsp;explains how we collect, use, disclose, and safeguard
      your information when you visit our website&nbsp;<a data-cke-saved-href="http://www.medusadistribution.com/"
        href="http://www.medusadistribution.com/" target="_blank">www.medusadistribution.com</a>&nbsp;and our mobile
      application, including any other media form, media channel, mobile website, or mobile application related or
      connected thereto (collectively, the “Site”). Please read this&nbsp;Privacy&nbsp;Policy&nbsp;carefully. IF YOU DO
      NOT AGREE WITH THE TERMS OF THIS&nbsp;PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.</p>
    <p>We reserve the right to make changes to this&nbsp;Privacy&nbsp;Policy&nbsp;at any time and for any
      reason.&nbsp;We will alert you about any changes by updating the “Revised” date of this&nbsp;Privacy
      Policy.&nbsp;Any changes or modifications will be effective immediately upon posting the
      updated&nbsp;Privacy&nbsp;Policy&nbsp;on the Site, and you waive the right to receive specific notice of each such
      change or modification. You are encouraged to periodically review this&nbsp;Privacy&nbsp;Policy&nbsp;to stay
      informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have
      accepted the changes in any revised&nbsp;Privacy&nbsp;Policy&nbsp;by your continued use of the Site after the date
      such revised&nbsp;Privacy&nbsp;Policy&nbsp;is posted.</p>
    <p><br></p>
    <p><strong><span class="ql-cursor">﻿</span>COLLECTION OF YOUR INFORMATION</strong></p>
    <p><strong>﻿</strong></p>
    <p>We may collect information about you in a variety of ways.&nbsp;The information we may collect on the Site
      includes:</p>
    <p><strong>Personal Data</strong></p>
    <p>Personally identifiable information, such as your name, shipping address, email address, and telephone number,
      and demographic information, such as your age, gender, hometown, and interests,&nbsp;that you voluntarily give to
      us&nbsp;when you register with the Site or&nbsp;our mobile application, or&nbsp;when you choose to participate in
      various activities related to the Site&nbsp;and our mobile application, such as online chat and message boards.
      You are under no obligation to provide us with personal information of any kind, however your refusal to do so may
      prevent you from using certain features of the&nbsp;Site&nbsp;and our mobile application.</p>
    <p><br></p>
    <p><strong>Derivative Data</strong></p>
    <p>Information our servers automatically collect when you access the Site, such as your IP address, your browser
      type, your operating system, your access times, and the pages you have viewed directly before and after accessing
      the Site.&nbsp;If you are using our mobile application, this information may also include your device name and
      type, your operating system, your phone number, your country, your likes and replies to a post, and other
      interactions with the application and other users via server log files, as well as any other information you
      choose to provide.</p>
    <p><br></p>
    <p><strong><span class="ql-cursor">﻿</span>Data from Contests, Giveaways, and Surveys</strong></p>
    <p>Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
    </p>
    <p><br></p>
    <p><strong><span class="ql-cursor">﻿</span>Mobile Application Information</strong></p>
    <p>If you connect using our mobile application:</p>
    <p>We may request to send you push notifications regarding your account or our Services. If you wish to opt-out from
      receiving these types of communications, you may turn them off in your device’s settings.</p>
    <p><br></p>
    <p><strong><span class="ql-cursor">﻿</span>USE OF YOUR INFORMATION</strong></p>
    <p><br></p>
    <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized
      experience.&nbsp;Specifically, we may use information collected about you via the&nbsp;Site&nbsp;or our mobile
      application&nbsp;to:</p>
    <ul>
      <li>Administer sweepstakes, promotions, and contests.</li>
      <li>Assist law enforcement and respond to subpoenas.</li>
      <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
      <li>Create and manage your account.</li>
      <li>Deliver targeted advertising, coupons, newsletters, and promotions, and other information regarding our
        website and mobile application to you.</li>
      <li>Email you regarding your account or order.</li>
      <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site and our mobile
        application.</li>
      <li>Generate a personal profile about you to make future visits to the Site and our mobile application more
        personalized.</li>
      <li>Increase the efficiency and operation of the Site and our mobile application.</li>
      <li>Monitor and analyze usage and trends to improve your experience with the Site and our mobile application.</li>
      <li>Notify you of updates to the Site and our mobile application.</li>
      <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
      <li>Perform other business activities as needed.</li>
      <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
      <li>Process payments and refunds.</li>
      <li>Request feedback and contact you about your use of the Site and our mobile application.</li>
      <li>Resolve disputes and troubleshoot problems.</li>
      <li>Respond to product and customer service requests.</li>
      <li>Send you a newsletter.</li>
      <li>Solicit support for the Site and our mobile application.</li>
    </ul>
    <p><br></p>
    <p><strong>DISCLOSURE OF YOUR INFORMATION</strong></p>
    <p>We may share information we have collected about you in certain situations. Your information may be disclosed as
      follows:&nbsp;</p>
    <p><br></p>
    <p><strong>By Law or to Protect Rights</strong></p>
    <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or
      remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may
      share your information as permitted or required by any applicable law, rule, or regulation.&nbsp;This includes
      exchanging information with other entities for fraud protection and credit risk reduction.</p>
    <p><br></p>
    <p><br></p>
    <p><strong>Affiliates</strong></p>
    <p>We may share your information with our affiliates, in which case we will require those affiliates to honor
      this&nbsp;Privacy&nbsp;Policy. Affiliates include our parent company and any subsidiaries, joint venture partners
      or other companies that we control or that are under common control with us.</p>
    <p><br></p>
    <p><br></p>
    <p><strong>Business Partners</strong></p>
    <p>We may share your information with our business partners to offer you certain products, services or promotions.
    </p>
    <p><br></p>
    <p><br></p>
    <p><strong>Other Third Parties</strong></p>
    <p>We may share your information with advertisers and investors for the purpose of conducting general business
      analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.
    </p>
    <p><br></p>
    <p><br></p>
    <p><strong>Sale or Bankruptcy</strong></p>
    <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we
      may transfer your information to the successor entity.&nbsp;If we go out of business or enter bankruptcy, your
      information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may
      occur and that the transferee may decline honor commitments we made in this&nbsp;Privacy&nbsp;Policy.</p>
    <p><br></p>
    <p><br></p>
    <p>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we
      have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence,
      emails or other communications from third parties, you are responsible for contacting the third party
      directly.&nbsp;&nbsp;</p>
    <p><br></p>
    <p><br></p>
    <p><strong>﻿TRACKING TECHNOLOGIES&nbsp;</strong>&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p><br></p>
    <p><strong>Cookies and Web Beacons&nbsp;</strong>&nbsp;</p>
    <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site&nbsp;and our mobile
      application&nbsp;to help customize the Site&nbsp;and our mobile application&nbsp;and improve your experience. For
      more information on how we use cookies, please refer to our Cookie&nbsp;Policy&nbsp;posted on the Site, which is
      incorporated into this&nbsp;Privacy&nbsp;Policy. By using the Site, you agree to be bound by our
      Cookie&nbsp;Policy.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p><br></p>
    <p><br></p>
    <p><strong>Website Analytics</strong></p>
    <p>We may also partner with selected third-party vendors, such as&nbsp;Google Analytics,&nbsp;to allow tracking
      technologies and remarketing services on the Site&nbsp;and our mobile application&nbsp;through the use of first
      party cookies and third-party cookies, to, among other things, analyze and track users’ use of
      the&nbsp;Site&nbsp;and our mobile application, determine the popularity of certain content and better understand
      online activity. By accessing the Site, our mobile application, you consent to the collection and use of your
      information by these third-party vendors. You are encouraged to review their&nbsp;privacy&nbsp;policy&nbsp;and
      contact them directly for responses to your questions. We do not transfer personal information to these
      third-party vendors. However, If you do not want any information to be collected and used by tracking
      technologies, you can visit the third-party vendor or&nbsp;<a
        data-cke-saved-href="https://developers.facebook.com/docs/facebook-login/permissions"
        href="https://developers.facebook.com/docs/facebook-login/permissions" target="_blank">Network Advertising
        Initiative Opt-Out Tool</a>&nbsp;or&nbsp;<a data-cke-saved-href="http://www.aboutads.info/choices/"
        href="http://www.aboutads.info/choices/" target="_blank">Digital Advertising Alliance Opt-Out Tool</a>.</p>
    <p>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or
      erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or
      settings.&nbsp;&nbsp;&nbsp;</p>
    <p><br></p>
    <p><br></p>
    <p><strong>THIRD-PARTY WEBSITES</strong></p>
    <p>The Site&nbsp;and our mobile application&nbsp;may contain links to third-party websites and applications of
      interest, including advertisements and external services, that are not affiliated with us. Once you have used
      these links to leave the Site&nbsp;or our mobile application, any information you provide to these third parties
      is not covered by this&nbsp;Privacy&nbsp;Policy, and we cannot guarantee the safety and&nbsp;privacy&nbsp;of your
      information. Before visiting and providing any information to any third-party websites, you should inform yourself
      of the&nbsp;privacypolicies and practices (if any) of the third party responsible for that website, and should
      take those steps necessary to, in your discretion, protect the&nbsp;privacy&nbsp;of your information. We are not
      responsible for the content or&nbsp;privacy&nbsp;and security practices and policies of any third parties,
      including other sites, services or applications that may be linked to or from the&nbsp;Site&nbsp;or our mobile
      application.</p>
    <p><br></p>
    <p><br></p>
    <p><strong>SECURITY OF YOUR INFORMATION</strong></p>
    <p>We use administrative, technical, and physical security measures to help protect your personal information. While
      we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite
      our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be
      guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to
      interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide
      personal information.</p>
    <p><br></p>
    <p><br></p>
    <p><strong>POLICY&nbsp;FOR CHILDREN</strong></p>
    <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of
      any data we have collected from children under age 13, please contact us using the contact information provided
      below.</p>
    <p><br></p>
    <p><br></p>
    <p><strong>CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</strong></p>
    <p>Most web browsers and some mobile operating systems&nbsp;and our mobile applications&nbsp;include a Do-Not-Track
      (“DNT”) feature or setting you can activate to signal your&nbsp;privacypreference not to have data about your
      online browsing activities monitored and collected.&nbsp;No uniform technology standard for recognizing and
      implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any
      other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
      tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version
      of this&nbsp;Privacy&nbsp;Policy.&nbsp;&nbsp;&nbsp;</p>
    <p><br></p>
    <p><strong>OPTIONS REGARDING YOUR INFORMATION</strong></p>
    <p><strong>﻿</strong></p>
    <p><strong>Account Information</strong></p>
    <p>You may at any time review or change the information in your account or terminate your account by:</p>
    <ul>
      <li>&nbsp;&nbsp;Logging into user account settings and updating the user account.</li>
      <li>&nbsp;&nbsp;Contacting us using the contact information provided.</li>
    </ul>
    <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our
      active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems,
      assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
    <p><br></p>
    <p><br></p>
    <p><strong>Emails and Communications</strong></p>
    <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>
    <ul>
      <li>&nbsp;&nbsp;Contacting us using the contact information provided.</li>
    </ul>
    <p>If you no longer wish to receive correspondence, emails, or other communications from third parties, you are
      responsible for contacting the third party directly.</p>
    <p><br></p>
    <p><strong>CALIFORNIA&nbsp;PRIVACY&nbsp;RIGHTS</strong></p>
    <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
      California residents to request and obtain from us, once a year and free of charge, information about categories
      of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
      addresses of all third parties with which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a request, please submit your request in
      writing to us using the contact information provided below.</p>
    <p>If you are under 18 years of age, reside in California, and have a registered account with the Site&nbsp;or our
      mobile application, you have the right to request removal of unwanted data that you publicly post on
      the&nbsp;Site&nbsp;or our mobile application. To request removal of such data, please contact us using the contact
      information provided below, and include the email address associated with your account and a statement that you
      reside in California.&nbsp;We will make sure the data is not publicly displayed on the&nbsp;Site&nbsp;or our
      mobile application,&nbsp;but please be aware that the data may not be completely or comprehensively removed from
      our systems.</p>
    <p><br></p>
    <p><strong>CONTACT US</strong></p>
    <p>If you have questions or comments about this&nbsp;Privacy&nbsp;Policy, please contact us at:</p>
    <p><br></p>
    <p><br></p>
    <p><strong>Medusa Distribution LLC</strong></p>
    <p>9 Woodland Rd. Suite D, NJ 07006</p>
    <p>Roseland, NJ 07068</p>
    <p>(973) 808-1101</p>
  </div>
</template>


<script>
export default {
  name: 'PrivacyPolicy',
};
</script>
